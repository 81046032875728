/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from "react";

// This is basically a workaround the fact we can't use the simple
// CSS method of `scroll-behavior: smooth` on `html` in a SPA-like
// environment (each page transition would use it, and it would
// make navigation nauseous). Reverting back to Javascript!

const AnchorLink = ({ children, ...props }) => {
  const navigateSmoothly = event => {
    event.preventDefault();

    document.getElementById(props.href.replace(/^.*#/, "")).scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <a {...props} onClick={navigateSmoothly}>
      {children}
    </a>
  );
};

export default AnchorLink;
