import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import palm from "../images/palm.svg";
import lineMarble from "../images/line-marble.svg";
import shines from "../images/shines.svg";
import deepForest from "../images/deep-forest.jpg";
import carDial from "../images/car-dial.jpg";
import highwayFromTheTop from "../images/highway-from-the-top.jpg";
import sunshineInTheWoods from "../images/sunshine-in-the-woods.jpg";
import driverOnTheRoadAlternate from "../images/driver-on-the-road-alternate.jpg";
import yellowVestBetweenTwoTrucks from "../images/yellow-vest-between-two-trucks.jpg";
import talkingInsideAWarehouse from "../images/talking-inside-a-warehouse.jpg";
import semiTrailerHardcover from "../images/semi-trailer-hardcover.png";
import ecoPath from "../images/eco-path.svg";
import ecoDriveDashboard from "../images/eco-drive-dashboard.svg";
import {
  LabelBasCarboneLogo,
  ClimateNeutralNowLogo,
  OfficeNationalDesForetsLogo,
  GlobalClimateActionLogo,
} from "../components/svg-assets/GreenLabelsLogos";
import WeNowLogo from "../components/svg-assets/WeNowLogo";
import {
  CompensateIcon,
  GeoPointIcon,
  JerricanGreenIcon,
  MesureIcon,
  ReduceEmissions40,
  ReduceIcon,
  ValidateMesureIcon,
} from "../components/svg-assets/Icons";
import TwoTrucks from "../components/svg-assets/TwoTrucks";
import MesureBarChart from "../components/MesureBarChart";
import AnchorLink from "../components/AnchorLink";

const GreenRoadsPage = () => {
  const { t } = useTranslation();

  const [technicalScenarioSelected, selectTechnicalScenario] = useState(0);

  const scenarioClassName = scenarioNumber =>
    technicalScenarioSelected === scenarioNumber
      ? "fl-scenario fl-scenario--active"
      : "fl-scenario";

  const ScenarioLink = ({ children, scenarioId }) => (
    <div
      className={scenarioClassName(scenarioId)}
      onClick={() => selectTechnicalScenario(scenarioId)}
      onKeyPress={() => selectTechnicalScenario(scenarioId)}
      role="link"
      tabIndex={0}>
      {children}
    </div>
  );

  return (
    <Layout>
      <SEO title={t("green-roads.html-title")} />
      <div className="fl-grid fl-top-element">
        <div className="fl-bg-left fl-bg-palm" style={{ top: "-320px" }}>
          <div className="fl-hider-gradient" />
          <img src={palm} alt="" width="1648" height="2254" />
        </div>
        <div className="fl-first-half fl-green-context">
          <div className="fl-top-block">
            <div className="fl-tag">
              <Trans i18nKey="green-roads.headers.tag" />
            </div>
            <h2>
              <Trans i18nKey="green-roads.headers.title" />
            </h2>
          </div>
        </div>
        <div className="fl-very-second-half fl-top-block">
          <p className="fl-strong">
            <Trans i18nKey="green-roads.headers.p-choose" />
          </p>
          <Trans parent="p" i18nKey="green-roads.headers.p-drive-carbon-neutral" />
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "120px" }}>
        <div className="fl-pan fl-pan--left-soft-gradient fl-green-context fl-grid">
          <img className="fl-pan-bg" src={deepForest} alt="" />
        </div>
      </div>

      <div className="fl-floating-image-bubble fl-very-soft-bubble" style={{ marginTop: "20px" }}>
        <img src={driverOnTheRoadAlternate} loading="lazy" alt="" />
      </div>
      <div className="fl-grid" style={{ marginTop: "104px" }}>
        <div className="fl-first-half fl-green-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="green-roads.first-block.tag" />
          </div>
          <div className="fl-big-block">
            <h2>
              <Trans i18nKey="green-roads.first-block.title" />
            </h2>
            <Trans parent="p" i18nKey="green-roads.first-block.p-carriers-like-shippers-know-it" />
            <Trans parent="p" i18nKey="green-roads.first-block.p-buyers-more-than-ever" />
            <Trans
              parent="p"
              i18nKey="green-roads.first-block.p-but-transport-agents-are-not-ready"
            />
          </div>
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "69px" }}>
        <div className="fl-first-half fl-svg-width-wrapper">
          <TwoTrucks />
        </div>
        <div className="fl-second-half fl-blue-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="green-roads.second-block.tag" />
          </div>
          <div className="fl-big-block">
            <h2>
              <Trans i18nKey="green-roads.second-block.title" />
            </h2>
            <Trans
              parent="p"
              i18nKey="green-roads.second-block.p-low-carbon-transition-is-strategic"
            />
            <Trans parent="p" i18nKey="green-roads.second-block.p-to-support-transport-agents" />
            <Trans parent="p" i18nKey="green-roads.second-block.p-wenow-has-a-lot-of-labels" />
          </div>
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "129px" }}>
        <div className="fl-logo-list">
          <LabelBasCarboneLogo />
          <ClimateNeutralNowLogo />
          <OfficeNationalDesForetsLogo />
          <GlobalClimateActionLogo />
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "129px" }}>
        <div className="fl-first-third fl-medium-block fl-green-context">
          <div className="fl-tag">
            <Trans i18nKey="green-roads.a-unique-offer.tag" />
          </div>
          <h2 style={{ marginTop: "16px" }}>
            <Trans i18nKey="green-roads.a-unique-offer.title" />
          </h2>

          <div className="fl-partnership">
            <div className="fl-partnership-with">
              <Trans i18nKey="green-roads.a-unique-offer.in-partnership-with" />
            </div>
            <WeNowLogo />
          </div>
        </div>
        <div className="fl-second-half fl-medium-block fl-green-context">
          <Trans parent="p" i18nKey="green-roads.a-unique-offer.p-three-steps" />
          <AnchorLink href="#01-measure" className="fl-jump-to">
            <MesureIcon />
            <code>
              <Trans i18nKey="green-roads.a-unique-offer.three-steps.first.number" />
            </code>
            <Trans i18nKey="green-roads.a-unique-offer.three-steps.first.title" />
          </AnchorLink>
          <AnchorLink href="#02-reduce" className="fl-jump-to">
            <ReduceIcon />
            <code>
              <Trans i18nKey="green-roads.a-unique-offer.three-steps.second.number" />
            </code>
            <Trans i18nKey="green-roads.a-unique-offer.three-steps.second.title" />
          </AnchorLink>
          <AnchorLink href="#03-offset" className="fl-jump-to">
            <CompensateIcon />
            <code>
              <Trans i18nKey="green-roads.a-unique-offer.three-steps.third.number" />
            </code>
            <Trans i18nKey="green-roads.a-unique-offer.three-steps.third.title" />
          </AnchorLink>
        </div>
      </div>
      <div
        id="01-measure"
        className="fl-lighter-blue-wrapper"
        style={{ marginTop: "104px", paddingBottom: "144px" }}>
        <div className="fl-grid" style={{ marginTop: "104px" }}>
          <div className="fl-pan fl-slim-pan fl-pan--lighten fl-pan--left-gradient fl-pan--blueify fl-grid">
            <img className="fl-pan-bg" src={carDial} loading="lazy" alt="" />
            <div className="fl-first-third fl-green-context fl-vertical-center">
              <h2>
                <Trans parent="span" i18nKey="green-roads.mesure.step-number" />
                <Trans i18nKey="green-roads.mesure.title" />
              </h2>
            </div>
          </div>
        </div>

        <div className="fl-grid" style={{ marginTop: "80px" }}>
          <div className="fl-first-third fl-medium-block">
            <h2>
              <Trans i18nKey="green-roads.mesure.a-detailed-carbon-footprint" />
            </h2>
          </div>
          <div className="fl-second-half fl-medium-block">
            <p className="fl-white">
              <Trans i18nKey="green-roads.mesure.p-the-first-step" />
            </p>
          </div>
        </div>

        <div className="fl-grid" style={{ marginTop: "64px" }}>
          <div className="fl-first-third fl-explanations fl-offset-tag">
            <Trans parent="p" i18nKey="green-roads.mesure.p-thats-why-we-apply" />
          </div>
          <div className="fl-second-half fl-green-context">
            <div className="fl-tag fl-naked-tag">
              <Trans i18nKey="green-roads.mesure.first-check-list.tag" />
            </div>
            <ul className="fl-check-list">
              <Trans parent="li" i18nKey="green-roads.mesure.first-check-list.first" />
              <Trans parent="li" i18nKey="green-roads.mesure.first-check-list.second" />
              <Trans parent="li" i18nKey="green-roads.mesure.first-check-list.third" />
            </ul>
          </div>
        </div>
        <div className="fl-grid" style={{ marginTop: "48px" }}>
          <div className="fl-first-third fl-explanations fl-offset-tag">
            <Trans parent="p" i18nKey="green-roads.mesure.p-in-general-methods-stops-here" />
          </div>
          <div className="fl-second-half fl-blue-context">
            <div className="fl-tag fl-naked-tag">
              <Trans i18nKey="green-roads.mesure.second-check-list.tag" />
            </div>
            <ul className="fl-check-list">
              <Trans parent="li" i18nKey="green-roads.mesure.second-check-list.solo-prequel" />
            </ul>
            <ul className="fl-check-list">
              <Trans parent="li" i18nKey="green-roads.mesure.second-check-list.first" />
              <Trans parent="li" i18nKey="green-roads.mesure.second-check-list.second" />
              <Trans parent="li" i18nKey="green-roads.mesure.second-check-list.third" />
              <Trans parent="li" i18nKey="green-roads.mesure.second-check-list.fourth" />
            </ul>
          </div>
        </div>
        <div className="fl-grid" style={{ marginTop: "48px" }}>
          <div className="fl-first-third fl-explanations">
            <Trans parent="p" i18nKey="green-roads.mesure.p-more-realistic" />
          </div>
          <div className="fl-second-half">
            <MesureBarChart />
            <div className="fl-conclusion">
              <Trans parent="p" i18nKey="green-roads.mesure.conclusion" />
            </div>
          </div>
        </div>
      </div>
      <div
        id="02-reduce"
        className="fl-dot-square-pattern"
        style={{ paddingTop: "104px", paddingBottom: "104px" }}>
        <div className="fl-grid">
          <div className="fl-pan fl-slim-pan fl-pan--lighten fl-pan--left-gradient fl-pan--blueify fl-grid">
            <img className="fl-pan-bg" src={highwayFromTheTop} loading="lazy" alt="" />
            <div className="fl-first-third fl-green-context fl-vertical-center">
              <h2>
                <Trans parent="span" i18nKey="green-roads.reduce.step-number" />
                <Trans i18nKey="green-roads.reduce.title" />
              </h2>
            </div>
          </div>
        </div>
        <div className="fl-grid" style={{ marginTop: "80px" }}>
          <div className="fl-first-third fl-medium-block">
            <h2>
              <Trans i18nKey="green-roads.reduce.actions-to-reduce-impacts" />
            </h2>
          </div>
          <div className="fl-second-half fl-medium-block">
            <p className="fl-strong">
              <Trans i18nKey="green-roads.reduce.p-the-second-step-is-reduction" />
            </p>
            <Trans parent="p" i18nKey="green-roads.reduce.p-partners-engagement" />
          </div>
        </div>
        <div className="fl-grid" style={{ marginTop: "80px" }}>
          <div className="fl-first-half">
            <ScenarioLink scenarioId={0}>
              <code className="fl-scenario-number">
                <Trans i18nKey="green-roads.reduce.scenarios.first.number" />
              </code>
              <p className="fl-scenario-title">
                <Trans i18nKey="green-roads.reduce.scenarios.first.title" />
              </p>
              <p className="fl-scenario-description">
                <Trans i18nKey="green-roads.reduce.scenarios.first.description" />
              </p>
            </ScenarioLink>

            <ScenarioLink scenarioId={1}>
              <code className="fl-scenario-number">
                <Trans i18nKey="green-roads.reduce.scenarios.second.number" />
              </code>
              <p className="fl-scenario-title">
                <Trans i18nKey="green-roads.reduce.scenarios.second.title" />
              </p>
              <p className="fl-scenario-description">
                <Trans i18nKey="green-roads.reduce.scenarios.second.description" />
              </p>
            </ScenarioLink>

            <ScenarioLink scenarioId={2}>
              <code className="fl-scenario-number">
                <Trans i18nKey="green-roads.reduce.scenarios.third.number" />
              </code>
              <p className="fl-scenario-title">
                <Trans i18nKey="green-roads.reduce.scenarios.third.title" />
              </p>
              <p className="fl-scenario-description">
                <Trans i18nKey="green-roads.reduce.scenarios.third.description" />
              </p>
            </ScenarioLink>
          </div>

          {technicalScenarioSelected === 0 ? (
            <div className="fl-second-half">
              <div className="fl-scenario-example-block">
                <h3>
                  <Trans i18nKey="green-roads.reduce.scenarios.examples.vehicle-generation" />
                </h3>
                <Trans
                  parent="p"
                  i18nKey="green-roads.reduce.scenarios.examples.p-by-prefering-euro6"
                />
                <img
                  src={semiTrailerHardcover}
                  alt=""
                  title=""
                  width="268"
                  height="63"
                  loading="lazy"
                />
                <ul className="fl-pills fl-pills--big">
                  <li>EURO4</li>
                  <li>EURO5</li>
                  <li className="fl-pill--active">EURO6</li>
                </ul>
              </div>
              <div className="fl-scenario-example-block">
                <h3>
                  <Trans i18nKey="green-roads.reduce.scenarios.examples.kind-of-fuel-burned" />
                </h3>
                <Trans
                  parent="p"
                  i18nKey="green-roads.reduce.scenarios.examples.p-clean-energy-engines"
                />
                <Trans
                  parent="p"
                  i18nKey="green-roads.reduce.scenarios.examples.p-all-alternative-fuel-are-not-the-same"
                />
                <ul className="fl-pills fl-pills--big fl-pills--fuel">
                  <li
                    style={{
                      color: "#00DAA2",
                      backgroundColor: "rgba(0,219,163,0.10)",
                      border: "0 none",
                    }}>
                    <Trans i18nKey="green-roads.reduce.scenarios.examples.fuels.b100" />
                  </li>
                  <li
                    style={{
                      color: "#E1FF1F",
                      backgroundColor: "rgba(225,255,31,0.10)",
                      border: "0 none",
                    }}>
                    <Trans i18nKey="green-roads.reduce.scenarios.examples.fuels.hydrogen" />
                  </li>
                  <li
                    style={{
                      color: "#FF4949",
                      backgroundColor: "rgba(255,73,73,0.20)",
                      border: "0 none",
                    }}>
                    <Trans i18nKey="green-roads.reduce.scenarios.examples.fuels.gnl" />
                  </li>
                  <li
                    style={{
                      color: "#FF4949",
                      backgroundColor: "rgba(255,73,73,0.20)",
                      border: "0 none",
                    }}>
                    <Trans i18nKey="green-roads.reduce.scenarios.examples.fuels.gnc" />
                  </li>
                </ul>
                {
                  // TODO: Add the "alternative fuel" modal.
                  /*
                <p className="fl-link-next">
                  <a href="#">
                    <Trans i18nKey="green-roads.reduce.scenarios.examples.learn-more-on-alternative-fuel" />
                  </a>
                </p>
                */
                }
              </div>
            </div>
          ) : undefined}

          {technicalScenarioSelected === 1 ? (
            <div className="fl-second-half">
              <div className="fl-scenario-example-block">
                <h3>
                  <Trans i18nKey="green-roads.reduce.scenarios.examples.eco-itinerary" />
                </h3>

                <Trans
                  parent="p"
                  i18nKey="green-roads.reduce.scenarios.examples.p-by-ensuring-we-use-the-road"
                />

                <div className="fl-co2-reduction">
                  <ReduceEmissions40 />
                  <Trans i18nKey="green-roads.reduce.scenarios.examples.co2-reduction" />
                </div>

                <img src={ecoPath} alt="" width="498" height="413" />
              </div>
            </div>
          ) : undefined}

          {technicalScenarioSelected === 2 ? (
            <div className="fl-second-half">
              <div className="fl-scenario-example-block">
                <h3>
                  <Trans i18nKey="green-roads.reduce.scenarios.examples.eco-driving" />
                </h3>

                <Trans
                  parent="p"
                  i18nKey="green-roads.reduce.scenarios.examples.p-it-consists-on"
                />

                <div className="fl-co2-reduction">
                  <ReduceEmissions40 />
                  <Trans i18nKey="green-roads.reduce.scenarios.examples.co2-reduction" />
                </div>

                <img src={ecoDriveDashboard} alt="" width="506" height="469" loading="lazy" />
              </div>
            </div>
          ) : undefined}
        </div>
      </div>

      <div className="fl-grid" style={{ position: "relative", zIndex: 1 }}>
        <div className="fl-pan fl-big-pan fl-pan--with-left-img fl-grid">
          <img className="fl-pan-bg" src={yellowVestBetweenTwoTrucks} loading="lazy" alt="" />
          <div className="fl-second-half fl-vertical-center fl-medium-block">
            <h3>
              <Trans i18nKey="green-roads.reduce.contact-us.title" />
            </h3>
            <p className="fl-strong">
              <Trans i18nKey="green-roads.reduce.contact-us.p-you-want-to-position-yourself" />
            </p>
            <Trans parent="p" i18nKey="green-roads.reduce.contact-us.p-if-you-want-to-evolve" />
            <p className="fl-link-next">
              <Link to="/sign-up/carrier/">
                <Trans i18nKey="green-roads.reduce.contact-us.ask-to-be-contacted" />
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="fl-bg-left fl-bg-marble" style={{ marginTop: "-250px" }}>
        <div className="fl-hider-gradient" />
        <img src={lineMarble} alt="" loading="lazy" />
      </div>
      <div className="fl-grid" style={{ marginTop: "80px" }}>
        <div className="fl-first-third fl-medium-block">
          <h2>
            <Trans i18nKey="green-roads.reduce.cee-to-evolve-your-fleet" />
          </h2>
        </div>
        <div className="fl-second-half fl-medium-block">
          <p className="fl-strong">
            <Trans i18nKey="green-roads.reduce.p-all-fretlink-partners" />
          </p>
          <p className="fl-white">
            <Trans i18nKey="green-roads.reduce.p-this-aims-to-promote" />
          </p>
        </div>
      </div>
      <div className="fl-four-cards fl-grid" style={{ marginTop: "48px" }}>
        <section>
          <ReduceIcon />
          <Trans parent="p" i18nKey="green-roads.reduce.evolutions.replace-vehicles" />
        </section>
        <section>
          <GeoPointIcon />
          <Trans parent="p" i18nKey="green-roads.reduce.evolutions.embeded-telematics" />
        </section>
        <section>
          <ValidateMesureIcon />
          <Trans parent="p" i18nKey="green-roads.reduce.evolutions.driver-trainings" />
        </section>
        <section>
          <JerricanGreenIcon />
          <Trans parent="p" i18nKey="green-roads.reduce.evolutions.fuel-monitoring" />
        </section>
      </div>
      <div className="fl-grid" style={{ marginTop: "40px" }}>
        <div className="fl-second-half fl-medium-block">
          <p className="fl-footline">
            {/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content */}
            <Trans
              i18nKey="green-roads.reduce.footline"
              components={{ "contact-link": <Link to="/contact" /> }}
            />
            {/* eslint-enable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content */}
          </p>
        </div>
      </div>

      <div
        id="03-offset"
        className="fl-lighter-blue-wrapper"
        style={{ marginTop: "104px", paddingBottom: "104px" }}>
        <div className="fl-grid" style={{ marginTop: "104px" }}>
          <div className="fl-pan fl-slim-pan fl-pan--lighten fl-pan--left-gradient fl-pan--blueify fl-grid">
            <img className="fl-pan-bg" src={sunshineInTheWoods} loading="lazy" alt="" />
            <div className="fl-first-third fl-green-context fl-vertical-center">
              <h2>
                <Trans parent="span" i18nKey="green-roads.offset.step-number" />
                <Trans i18nKey="green-roads.offset.title" />
              </h2>
            </div>
          </div>
        </div>
        <div className="fl-grid" style={{ marginTop: "80px" }}>
          <div className="fl-first-third fl-medium-block">
            <h2>
              <Trans i18nKey="green-roads.offset.concrete-measures" />
            </h2>
          </div>
          <div className="fl-second-half fl-medium-block">
            <p className="fl-white">
              <Trans i18nKey="green-roads.offset.p-we-go-beyond" />
            </p>
          </div>
        </div>
        {
          // TODO: Actually implement this carousel and plug it to a JSON source.
          /*
        <div className="fl-grid fl-green-case-carousel-wrapper" style={{ marginTop: "104px" }}>
          <div className="fl-green-case-carousel">
            <div className="fl-green-case">
              <div className="fl-illustration"></div>
              <p className="fl-green-case-title">Centrale hydroélectrique</p>
              <div className="fl-green-case-facts">
                <div className="fl-green-case-fact">
                  <strong>10,7 MW</strong>
                  <span>de capacité installée</span>
                </div>
                <div className="fl-green-case-fact">
                  <strong>43 200 T</strong>
                  <span>CO₂ réduites (2013-2015)</span>
                </div>
                <div className="fl-green-case-fact">
                  <strong>300</strong>
                  <span>emplois générés</span>
                </div>
              </div>
            </div>
            <div className="fl-green-case">
              <div className="fl-illustration"></div>
              <p className="fl-green-case-title">Parc éolien</p>
              <div className="fl-green-case-facts">
                <div className="fl-green-case-fact">
                  <strong>50,4 MW</strong>
                  <span>de capacité installée</span>
                </div>
                <div className="fl-green-case-fact">
                  <strong>60 500 T</strong>
                  <span>CO₂ réduites (sur 7 ans)</span>
                </div>
                <div className="fl-green-case-fact">
                  <strong>60</strong>
                  <span>éoliennes installées</span>
                </div>
              </div>
            </div>
            <div className="fl-green-case">
              <div className="fl-illustration"></div>
              <p className="fl-green-case-title">Centrale hydroélectrique</p>
              <div className="fl-green-case-facts">
                <div className="fl-green-case-fact">
                  <strong>160 MW</strong>
                  <span>de capacité installée</span>
                </div>
                <div className="fl-green-case-fact">
                  <strong>101 500 T</strong>
                  <span>CO₂ réduites (par an)</span>
                </div>
                <div className="fl-green-case-fact">
                  <strong>500 100 T</strong>
                  <span>CO₂ réduites (sur 5 ans)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        */
        }
        <div className="fl-grid" style={{ marginTop: "104px" }}>
          <div className="fl-first-third fl-medium-block">
            <h2>
              <Trans i18nKey="green-roads.offset.each-project-is-selected" />
            </h2>
          </div>
          <div className="fl-second-half fl-medium-block">
            <p className="fl-white">
              <Trans i18nKey="green-roads.offset.p-every-project-are-labellized" />
            </p>
          </div>
        </div>
        <div className="fl-grid" style={{ marginTop: "48px" }}>
          <div className="fl-first-third fl-medium-block">
            <p>
              <Trans i18nKey="green-roads.offset.p-we-combine-projects-smartly" />
            </p>
          </div>
        </div>
        <div className="fl-grid">
          <div className="fl-both-half">
            <hr />
          </div>
        </div>

        <div className="fl-bg-left fl-bg-shines" style={{ marginTop: "-75px" }}>
          <div className="fl-hider-gradient" />
          <img src={shines} alt="" loading="lazy" />
        </div>
        <div className="fl-grid" style={{ rowGap: "25px" }}>
          <div className="fl-first-third fl-medium-block">
            <h2>
              <Trans i18nKey="green-roads.offset.talk-about-your-rse-efforts" />
            </h2>
          </div>
          <div className="fl-step-block fl-second-half">
            <code>
              <Trans i18nKey="green-roads.offset.steps.first.number" />
            </code>
            <h3>
              <Trans i18nKey="green-roads.offset.steps.first.title" />
            </h3>
            <Trans i18nKey="green-roads.offset.steps.first.description" />
          </div>
          <div className="fl-step-block fl-first-half">
            <code>
              <Trans i18nKey="green-roads.offset.steps.second.number" />
            </code>
            <h3>
              <Trans i18nKey="green-roads.offset.steps.second.title" />
            </h3>
            <Trans i18nKey="green-roads.offset.steps.second.description" />
          </div>
          <div className="fl-step-block fl-second-half">
            <code>
              <Trans i18nKey="green-roads.offset.steps.third.number" />
            </code>
            <h3>
              <Trans i18nKey="green-roads.offset.steps.third.title" />
            </h3>
            <Trans i18nKey="green-roads.offset.steps.third.description" />
          </div>
        </div>

        <div className="fl-grid" style={{ marginTop: "104px" }}>
          <div className="fl-pan fl-big-pan fl-pan--with-left-img fl-grid">
            <img className="fl-pan-bg" src={talkingInsideAWarehouse} loading="lazy" alt="" />
            <div className="fl-second-half fl-vertical-center fl-medium-block">
              <h3>
                <Trans i18nKey="green-roads.offset.contact-us.title" />
              </h3>
              <p className="fl-strong">
                <Trans i18nKey="green-roads.offset.contact-us.p-you-want-to-limit" />
              </p>
              <Trans parent="p" i18nKey="green-roads.offset.contact-us.p-if-you-want-to-include" />
              <p className="fl-link-next">
                <Link to="/sign-up/shipper/">
                  <Trans i18nKey="green-roads.offset.contact-us.ask-to-be-contacted" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GreenRoadsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
