import React from "react";

export default function WeNowLogo() {
  return (
    <svg viewBox="0 0 135.7 23.8" width="136" height="24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M7.5 23.7a8.7 8.7 0 01-6.7-4.9c-.8-1.5-.8-2-.8-9.4C0 1.8 0 2 1 1.3l.5-.4H6v7.4c0 8.2 0 8 .8 8.8 1.3 1.2 3.3.9 4.1-.6l.3-.6V1h6l.1 7.6c0 6.7.1 7.6.3 8a2.6 2.6 0 003.4 1.1c.6-.3 1.3-1 1.5-1.5l.1-7.8V1h6v8c-.1 6.2-.1 8.2-.3 8.6-.5 1.7-1.8 3.5-3 4.5a8.2 8.2 0 01-5.4 1.7 8 8 0 01-5.1-1.6l-.5-.4-.5.4a10 10 0 01-2.8 1.4c-.7.2-2.8.3-3.5.2zm34.4 0c-2-.2-4.1-1-5.6-2.2a11.7 11.7 0 01-4.5-10.3c0-1.6.1-2 .5-3.2 1.3-4 4-6.7 8.1-7.7 1.1-.2 1.7-.3 3.2-.3C47 0 49.5.7 52 2.5l1 .7-.1.7c-.5 3.6-2.2 6.8-5.2 10a26 26 0 01-4.1 3.3l-.9.6h3c2.7 0 4.3 0 6.3-.3h.6l-.1.4c-.2.7-1 2.1-1.6 2.8a11 11 0 01-5.1 2.8c-1 .2-3.2.3-4 .2zm-2.3-10.2a24.3 24.3 0 006.7-7.2c0-.1-.5-.2-1.8-.3-2.2-.2-3.5.3-5 1.6-.8.8-1.3 1.7-1.6 2.9a6 6 0 000 3c.3 1 .2 1 1.7 0zm32.1 3.7l-.1-7.2-.7-1.4c-.5-.7-.8-1-1.4-1.3a3 3 0 00-2-.5c-1.2 0-2 .3-3 1.2-1.2 1.3-1.3 1.4-1.3 9.2v6.5h-5.8V1.3h.6c1.1 0 2 .6 2.8 2l.7-.5c.9-.6 2.6-1.4 3.7-1.7a10 10 0 0111.4 5.7c.9 1.9 1 2.2 1 10v6.9h-5.9v-6.5zm12.6 6.5c-.1 0-.2-.6-.2-1.8V20l-.7-1c-1-1.4-1.4-2.4-1.9-3.9-.3-1-.3-1.3-.3-3.3 0-2 0-2.3.3-3.4a11.6 11.6 0 0116.3-7.3c3 1.5 5 4 5.9 7.5.2 1 .3 1.6.3 3.1 0 1.6 0 2-.3 3.2-1 3.6-3.5 6.5-6.7 7.8-1.6.7-2.6.9-4.2.9-1.7 0-2.8-.2-4.2-.7-1-.3-1.1-.4-1.3-.2-.6.5-2.5 1-3 .9zm9.7-6c2.7-.8 4.4-3.4 4.1-6.4-.2-1.8-1-3.3-2.2-4.2a5.6 5.6 0 00-6.2-.3c-1.9 1-3 3.6-2.6 6 .6 3.5 3.6 5.7 6.9 4.8zm20.7 6a8.7 8.7 0 01-7.2-6.5c-.3-1.1-.2-.7-.2-9V.9h5.8v15.2l.5.6a2.8 2.8 0 004.6-.2c.2-.4.3-1.3.3-8l.1-7.5h5.8v7.5c.2 8.2.1 8 .9 8.7 1 1 3.1.8 4-.3.6-.8.6-1 .6-8.7V1h2c2.4 0 2.7.2 3.4 1l.4.5v14.7l-.5 1.2a8.6 8.6 0 01-5.9 5.2c-.8.2-3 .3-3.8.1a9.3 9.3 0 01-3.4-1.5l-.6-.4-.6.4a8.7 8.7 0 01-6.2 1.5z"
      />
    </svg>
  );
}
