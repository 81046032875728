import React from "react";

import stylesheet from "./MesureBarChart.module.css";
import { Trans } from "gatsby-plugin-react-i18next";

export default function MesureBarChart() {
  return (
    <div className={stylesheet.chart}>
      <div className={`${stylesheet.column} ${stylesheet.yAxis}`}>
        <div />
        <div />
        <div />
        <div />
        <span>
          <Trans i18nKey="green-roads.mesure.mesure-bar-chart.reliability" />
        </span>
      </div>
      <div className={`${stylesheet.column} ${stylesheet.firstColumn}`}>
        <div className={stylesheet.hiddenCell} />
        <div />
        <div />
        <div />
        <span>
          <Trans i18nKey="green-roads.mesure.mesure-bar-chart.normal-methods" />
        </span>
      </div>
      <div className={`${stylesheet.column} ${stylesheet.secondColumn}`}>
        <div />
        <div />
        <div />
        <div />
        <span>
          <Trans i18nKey="green-roads.mesure.mesure-bar-chart.our-method" />
        </span>
      </div>
    </div>
  );
}
